<template>
  <vs-tabs class="status-tabs" v-if="status" alignment="fixed">
    <vs-tab label="Todos" @click="$emit('on-state-change', '')"> </vs-tab>
    <vs-tab
      v-for="state in sortFunc"
      v-bind:key="state.id"
      :label="state.name"
      @click="$emit('on-state-change', state.id)"
    >
    </vs-tab>
  </vs-tabs>
</template>

<script>
export default {
  props: ['status'],
  computed: {
    sortFunc: function () {
      return this.status.slice().sort(function (a, b) {
        return a.sequence > b.sequence ? 1 : -1;
      });
    },
  },
};
</script>

<style lang="scss">
.status-tabs {
  // VTab Active
  .vs-tabs--li.activeChild {
    background-color: #00d0eb;
    color: white !important;
  }
  .vs-tabs--li.activeChild > button > span {
    color: white;
  }
  // All VTabs
  .con-ul-tabs > ul > li {
    background-color: #2d4e6010;
    color: #2d4e60;
  }
  // Delete Line bottom
  .con-ul-tabs > span {
    display: none;
  }
}
</style>
