<template>
  <div v-show="loading" class="app-loading">
    <div id="div-with-loading"></div>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    watch(
      () => props.loading,
      (value) => {
        if (!value) {
          root.$vs.loading.close('#div-with-loading > .con-vs-loading');
        } else {
          root.$vs.loading({
            container: '#div-with-loading',
            scale: 0.6,
          });
        }
      }
    );

    onMounted(() => {});
  },
});
</script>
<style lang="scss">
.app-loading {
  .con-vs-loading {
    position: relative;
    background: inherit;
  }
  #div-with-loading {
    width: 200px;
    height: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
}
</style>
