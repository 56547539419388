<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-prompt
      class="socios-table__prompt"
      :active.sync="activePromptChangeState"
      title="Cambiar estado al servicio."
      @accept="sendChangeStateService"
      accept-text="Guardar"
      cancel-text="Descartar"
    >
      <h6 class="service-name font-medium truncate mb-2">
        Estado actual del servicio:
      </h6>
      <p class="service-name font-medium truncate mb-3">
        <vs-chip
          v-if="selectedService"
          color="primary"
          class="product-order-status"
        >
          {{ selectedService.state.name }}
        </vs-chip>
      </p>
      <vs-select
        class="w-full"
        label="Todos los estados disponibles."
        v-model="currentStateId"
        v-if="serviceStatuses && selectedService"
      >
        <vs-select-item
          :key="index"
          :text="item.name"
          :value="item.id"
          v-for="(item, index) in statesServicesFiltered()"
        />
      </vs-select>
    </vs-prompt>
    <!-- Card Filter -->
    <vx-card ref="filterCard" title="Filtros" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="text-sm text-san-juan font-bold">Fecha de inicio</label>
          <datepicker
            :language="langEs"
            :disabledDates="disabledDatesFrom"
            name="start-date"
            v-model="startDate"
          >
          </datepicker>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
          <datepicker
            :language="langEs"
            :disabledDates="disabledDatesTo"
            name="end-date"
            v-model="endDate"
          ></datepicker>
        </div>

        <div class="vx-col sm:w-1/4 w-full">
          <vs-select
            label="Socio logístico"
            v-model="deliverymenId"
            class="w-full"
            placeholder="Socio logístico"
            autocomplete
          >
            <vs-select-item :value="(item = null)" text="Todos" />
            <vs-select-item
              :value="item.id"
              v-for="(item, index) in deliverymen"
              :key="index"
              v-show="item.data_status == 'verified'"
              :text="`${item.code ? item.code : 'N/A'} - ${
                item.user.first_name + ' ' + item.user.last_name
              }`"
            />
          </vs-select>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <vs-select
            label="Metódos de pago"
            class="w-full"
            v-model="paymentMethod"
            placeholder="Seleccione un metodo"
          >
            <vs-select-item
              :key="index"
              :value="item.key"
              :text="item.value"
              v-for="(item, index) in paymentMethods"
            />
          </vs-select>
        </div>
        <div class="w-full flex gap-2 flex-1">
          <vs-button
            type="border"
            class="mb-4 md:mb-0 mt-3"
            @click="exportJarrisDispatches"
            >Exportar Despachos Jarris</vs-button
          >
          <vs-button
            type="border"
            class="mb-4 md:mb-0 mt-3"
            @click="exportJarrisCodes"
            >Exportar Códigos Jarris</vs-button
          >
        </div>
      </div>
    </vx-card>
    <div
      v-if="!efficacyDataLoading"
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-4"
    >
      <statistics-card-line
        hideChart
        class="mb-base"
        icon-right
        icon="ArrowDownIcon"
        :statistic="efficacyData.average_taken.toFixed(2) + ' Min.'"
        statisticTitle="Prom. Toma de servicio"
        color="success"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon-right
        icon="PocketIcon"
        :statistic="efficacyData.average_arrived.toFixed(2) + ' Min.'"
        statisticTitle="Prom. Arribo de servicio"
        color="warning"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon-right
        icon="FlagIcon"
        :statistic="efficacyData.average_delivery.toFixed(2) + ' Min.'"
        statisticTitle="Prom. Entrega de servicio"
        color="danger"
      />
    </div>

    <StatusTabs
      v-if="serviceStatuses"
      :status="serviceStatuses.filter((e) => e.is_done)"
      @on-state-change="
        stateId = $event;
        changeServiceStatus($event);
      "
    ></StatusTabs>

    <div
      v-if="loadingTotalServices"
      class="mb-4 transition-all duration-200 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4"
    >
      <div
        v-for="(item, index) in [1, 2, 3, 4, 5, 6]"
        :key="index"
        class="h-24 w-[10rem] rounded-lg border-grey-light bg-opacity-25 mb-8 shadow-md"
      ></div>
    </div>

    <div
      v-if="!loadingTotalServices"
      class="grid transition-all duration-200 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4"
    >
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalServices.total"
        statisticTitle="Cant. Servicios"
        color="success"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ArrowDownIcon"
        icon-right
        :statistic="totalServices.total_credit"
        statisticTitle="Cant. Crédito"
        color="danger"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalServices.total_cash"
        statisticTitle="Cant. Contado"
        color="success"
      />

      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalServices.total_value | currencyFormat"
        statisticTitle="Total Servicios"
        color="success"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalServices.total_value_cash | currencyFormat"
        statisticTitle="Total  Contado"
        color="success"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalServices.total_value_credit | currencyFormat"
        statisticTitle="Total  Crédito"
        color="success"
      />
    </div>

    <!-- History state prompt -->
    <vs-prompt
      :active.sync="showHistoricalPrompt"
      buttons-hidden
      title="Historial de estados"
    >
      <vs-table
        class="mt-3"
        v-if="selectedService && selectedService.stateHistorical"
        :data="selectedService.stateHistorical"
      >
        <template slot="thead">
          <vs-th> Estado </vs-th>
          <vs-th> Fecha </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.state.name }} </vs-td>
            <vs-td>{{ tr.created_at }} </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>
    <!-- Detail SERVICE -->
    <vs-prompt
      :active.sync="showDetailPrompt"
      buttons-hidden
      title="Detalle del Servicio"
    >
      <vs-table
        class="mt-3"
        v-if="detailService"
        :data="detailService.directions"
      >
        <template slot="thead">
          <vs-th> Direccion </vs-th>
          <vs-th> Descripcion </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.address }} </vs-td>
            <vs-td>{{ tr.description }} </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>

    <!-- Service image -->
    <vs-prompt
      :active.sync="showServiceImagePrompt"
      title="Fotos del servicio"
      @cancel="resetServiceImagePrompt"
      :buttons-hidden="true"
    >
      <div class="my-3" v-if="selectedService">
        <h6 class="service-name font-medium truncate mb-2">Observación</h6>
        <p v-if="selectedService.observation">
          {{ selectedService.observation }}
        </p>
        <p v-else>N/A</p>

        <h6 class="service-name font-medium truncate mt-5 mb-2">Imágenes</h6>
        <vs-table v-if="selectedService.images" :data="selectedService.images">
          <template slot="thead">
            <vs-th> Aliado </vs-th>
            <vs-th> Fecha </vs-th>
            <vs-th> Imagen </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.deliveryman.user.first_name }}
                {{ tr.deliveryman.user.last_name }}
              </vs-td>
              <vs-td>{{ tr.created_at }} </vs-td>
              <vs-td>
                <span class="flex justify-center items-center w-full">
                  <feather-icon
                    v-if="tr.url"
                    icon="ImageIcon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="viewImage(tr.url)"
                  />
                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-prompt>
    <!-- Service image -->

    <div class="flex">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4 tw-mb-3"
        v-model="searchQuery"
        placeholder="Buscar..."
      />
      <vs-button
        type="border"
        class="mb-4 md:mb-0"
        @click="exportServicesHistory"
        >Exportar</vs-button
      >
    </div>
    <app-loading :loading="loading"></app-loading>

    <vs-table
      ref="table"
      id="history-services-table"
      v-model="selected"
      :max-items="itemsPerPage"
      :total="total"
      :data="servicesHistory.data"
      v-if="
        servicesHistory.data &&
        servicesHistory.paginatorInfo &&
        !$apollo.loading
      "
      sst
      no-data-text="No hay servicios disponibles"
      @change-page="setCurrentPage"
    >
      <template slot="thead">
        <vs-th sort-key="id">Ref</vs-th>
        <vs-th sort-key="">Valor</vs-th>
        <vs-th sort-key="">Forma de pago</vs-th>
        <vs-th sort-key="">Remitente</vs-th>
        <vs-th sort-key="">Tel. Remitente</vs-th>
        <vs-th sort-key="">Destinatario</vs-th>
        <vs-th sort-key="">Tel. Destinatario</vs-th>
        <vs-th sort-key="">Partner</vs-th>
        <vs-th sort-key="">Tel Partner</vs-th>
        <vs-th sort-key="">Estado</vs-th>
        <vs-th sort-key="">Observación Aliado</vs-th>
        <vs-th sort-key="">Id. Factura</vs-th>
        <vs-th sort-key="">Referencia</vs-th>
        <vs-th sort-key="">V. del pedido</vs-th>
        <vs-th sort-key="">Ida y vuelta</vs-th>
        <vs-th sort-key="">Fecha y hora</vs-th>
        <vs-th>Acciones</vs-th>
      </template>
      v-model="search"

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="service-name font-medium truncate">{{ tr.id }}</p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.value | currencyFormat }}
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.payment_method == 'CASH' ? 'Efectivo' : 'Credito' }}
              </p>
            </vs-td>
            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.sender && tr.sender.name ? tr.sender.name : 'N/A' }}
              </p>
            </vs-td>
            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.sender && tr.sender.phone ? tr.sender.phone : 'N/A' }}
              </p>
            </vs-td>
            <vs-td>
              <p class="service-name font-medium truncate">
                {{
                  tr.destinatary && tr.destinatary.name
                    ? tr.destinatary.name
                    : 'N/A'
                }}
              </p>
            </vs-td>
            <vs-td>
              <p class="service-name font-medium truncate">
                {{
                  tr.destinatary && tr.destinatary.phone
                    ? tr.destinatary.phone
                    : 'N/A'
                }}
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{
                  tr.deliveryman && tr.deliveryman.user
                    ? tr.deliveryman.code +
                      ' - ' +
                      tr.deliveryman.user.first_name +
                      ' ' +
                      tr.deliveryman.user.last_name
                    : 'N/A'
                }}
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.deliveryman ? tr.deliveryman.user.phone : 'N/A' }}
              </p>
            </vs-td>
            <vs-td>
              <p class="service-name font-medium truncate">
                <vs-chip
                  color="primary"
                  class="product-order-status"
                  @click.native="
                    $gates.hasPermission('change_state_finished_services') &&
                      promptChangeState(tr)
                  "
                >
                  {{ tr.state.name }}
                </vs-chip>
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.observation ? tr.observation : 'N/A' }}
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.invoice_id ? tr.invoice_id : 'Sin facturar' }}
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.invoice ? tr.invoice : 'N/A' }}
              </p>
            </vs-td>

            <vs-td>
              <p
                v-if="tr.order_value"
                class="service-name font-medium truncate"
              >
                {{ tr.order_value | currencyFormat }}
              </p>
              <p
                v-if="!tr.order_value"
                class="service-name font-medium truncate"
              >
                N/A
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                <!-- {{ tr.round_trip ? 'Si' : 'No' }} -->

                <feather-icon
                  v-if="tr.round_trip"
                  icon="CheckIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  v-else
                  icon="XCircleIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </p>
            </vs-td>

            <vs-td>
              <p class="service-name font-medium truncate">
                {{ tr.created_at }}
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="InfoIcon"
                @click="showStateDetail(tr)"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
              />
              <feather-icon
                icon="ClockIcon"
                @click="showStateHistorical(tr)"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
              />
              <feather-icon
                icon="ImageIcon"
                @click="showServiceImageDetail(tr)"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </div>
</template>

<script>
import { ALL_STATES_SERVICES, SERVICES_HISTORY } from '@/graphql/queries';
import StatusTabs from '@/components/solicitud/StatusTabs';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';
import moment from 'moment';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import { DELIVERYMEN } from '@/graphql/queries';

import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  getCurrentInstance,
  ref,
} from '@vue/composition-api';
import AppLoading from '@/components/AppLoading';
import { useResult, useQuery } from '@vue/apollo-composable';
import { TOTAL_SERVICES, EFFICACY_DATA } from '@/graphql/queries';
import { UPDATE_STATE_SERVICE } from '@/graphql/mutations';
export default defineComponent({
  components: {
    StatusTabs,
    Datepicker,
    AppLoading,
    StatisticsCardLine,
  },
  setup(_, { root }) {
    const instance = getCurrentInstance();
    const state = reactive({
      startDate: new Date(),
      endDate: new Date(),
      status: 'ALL',
    });

    const deliverymenId = ref();

    // Watch start date
    watch(
      () => state.startDate,
      () => {
        instance.currentPage = 1;
      }
    );
    // Watch end date
    watch(
      () => state.endDate,
      () => {
        instance.currentPage = 1;
      }
    );

    watch(
      () => state.deliverymenId,
      (data) => {
        console.log(data);
        instance.currentPage = 1;
      }
    );

    const sDate = computed(() =>
      state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : ''
    );
    const eDate = computed(() =>
      state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : ''
    );
    const status = computed(() => {
      const status = 'ALL';
      if (state.status == '4') {
        return 'FINISHED';
      }
      if (state.status == '2') {
        return 'CANCELLED';
      }
      return status;
    });
    const paymentMethod = ref();

    const changeServiceStatus = (status) => {
      state.status = `${status}`;
    };

    // watch(paymentMethod, (value) => {
    //   console.log(value);
    // });

    const { result: totalServicesResult, loading: loadingTotalServices } =
      useQuery(
        TOTAL_SERVICES,
        {
          startDate: sDate,
          endDate: eDate,
          payment_method: paymentMethod,
          deliveryman_id: deliverymenId,
          statusId: status,
        },
        {
          fetchPolicy: 'cache-and-network',
        }
      );
    const totalServices = useResult(totalServicesResult);
    const disabledDatesTo = computed(() => {
      return { from: new Date() };
    });
    const disabledDatesFrom = computed(() => {
      return { from: new Date(state.endDate) };
    });

    const { result: efficacyDataResult, loading: efficacyDataLoading } =
      useQuery(
        EFFICACY_DATA,
        {
          startDate: sDate,
          endDate: eDate,
          payment_method: paymentMethod,
          deliverymen_id: deliverymenId,
        },
        {
          fetchPolicy: 'cache-and-network',
        }
      );

    const efficacyData = useResult(
      efficacyDataResult,
      null,
      (data) => data.efficacyData
    );

    const exportServicesHistory = () => {
      console.log('Export services');
      const fileName = 'servicios.xlsx';
      root.$http
        .post(
          'api/history-services/export',
          {
            startDate: sDate.value,
            endDate: eDate.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    const exportJarrisDispatches = () => {
      console.log('Export dispatches');
      const fileName = 'despachos.xlsx';
      root.$http
        .post(
          'api/dispatches/export',
          {
            startDate: sDate.value,
            endDate: eDate.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };
    const exportJarrisCodes = () => {
      const fileName = 'delegaciones.xlsx';
      root.$http
        .post(
          'api/jarris/delegations/export',
          {
            startDate: sDate.value,
            endDate: eDate.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      disabledDatesTo,
      disabledDatesFrom,
      sDate,
      eDate,
      changeServiceStatus,
      exportServicesHistory,
      loadingTotalServices,
      totalServices,
      paymentMethod,
      exportJarrisDispatches,
      exportJarrisCodes,
      deliverymenId,
      efficacyData,
      efficacyDataLoading,
      ...toRefs(state),
    };
  },

  data() {
    return {
      search: '',
      selected: [],
      langEs: es,
      activePromptChangeState: false,
      statesServices: [],
      services: [],
      stateId: '',
      itemsPerPage: 20,
      currentPage: 1,
      isMounted: false,
      servicesHistory: [],
      showCancelDialog: false,
      specialKey: '',
      confirmSpecialKey: '',
      service: [],
      showHistoricalPrompt: false,
      showServiceImagePrompt: false,
      editServiceModal: false,
      serviceToEdit: null,
      showDetailPrompt: false,
      selectedService: null,
      detailService: [],
      currentStateId: '',
      showTable: true,
      maxPageNumbers: 5,
      searchQuery: '',
      paymentMethods: [
        {
          key: null,
          value: 'Todos',
        },
        {
          key: 'CASH',
          value: 'Efectivo',
        },
        {
          key: 'CREDIT',
          value: 'Credito',
        },
      ],
      loading: false,
    };
  },

  apollo: {
    servicesHistory: {
      query: SERVICES_HISTORY,
      variables() {
        return {
          page: this.currentPage,
          first: this.itemsPerPage,
          state_id: this.stateId,
          startDate: this.sDate,
          endDate: this.eDate,
          search: this.searchQuery,
          payment_method: this.paymentMethod,
          deliveryman_id: this.deliverymenId,
        };
      },
    },
    serviceStatuses: {
      query: ALL_STATES_SERVICES,
      result({ data }) {
        if (data) {
          this.statesServices = data.serviceStatuses;
        }
      },
    },
    deliverymen: {
      query: DELIVERYMEN,
    },
  },
  computed: {
    totalPages() {
      if (this.servicesHistory.paginatorInfo) {
        return Math.ceil(
          this.servicesHistory.paginatorInfo.total /
            this.servicesHistory.paginatorInfo.perPage
        );
      }
      return 0;
    },
    total() {
      return this.servicesHistory.paginatorInfo
        ? this.servicesHistory.paginatorInfo.total
        : 0;
    },
  },
  methods: {
    statesServicesFiltered() {
      if (this.selectedService.state.code == 'CANCELLED') {
        return this.serviceStatuses
          .filter((a) => a.sequence == 1)
          .concat(this.selectedService.state);
      } else {
        return this.serviceStatuses
          .filter((a) => a.is_done == false && a.sequence != 1)
          .concat(this.selectedService.state);
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    sendChangeStateService() {
      const service = this.servicesHistory.data.find(
        (e) => e.id == this.selectedService.id
      );
      if (service) {
        service.state = this.serviceStatuses.find(
          (e) => e.id == this.currentStateId
        );
        this.changeServiceState(service.id, service.state.id);
      }
    },
    async changeServiceState(id, state_id) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_STATE_SERVICE,
          variables: {
            id,
            state_id,
          },
          update: (cache, { data: { updateStatusService } }) => {
            try {
              const data = cache.readQuery({
                query: SERVICES_HISTORY,
                variables: {
                  page: this.currentPage,
                  first: this.itemsPerPage,
                  state_id: this.stateId,
                  startDate: this.sDate,
                  endDate: this.eDate,
                  search: this.searchQuery,
                  payment_method: this.paymentMethod,
                  deliveryman_id: this.deliverymenId,
                },
              });
              this.servicesHistory.data = data;
              const removeIndex = data.servicesHistory.data
                .map((item) => item.id)
                .indexOf(updateStatusService.id);
              ~removeIndex && data.servicesHistory.data.splice(removeIndex, 1);
              cache.writeQuery({
                query: SERVICES_HISTORY,
                data,
                variables: {
                  page: this.currentPage,
                  first: this.itemsPerPage,
                  state_id: this.stateId,
                  startDate: this.sDate,
                  endDate: this.eDate,
                  search: this.searchQuery,
                  payment_method: this.paymentMethod,
                  deliveryman_id: this.deliverymenId,
                },
              });
            } catch {
              console.log();
            }
          },
        })
        .then((data) => {
          this.selectedService.stateHistorical =
            data.data.updateStatusService.stateHistorical;
          this.$vs.notify({
            title: 'Se ha cambiado el estado del servicio',
            text: `El estado nuevo del servicio es ${data.data.updateStatusService.state.name}`,
            color: 'success',
          });
        })
        .catch(() => {
          // this.$vs.notify({
          //   title: 'No se ha cambiado el estado del servicio',
          //   text: `Error al cambiar el estado del servicio.`,
          //   color: 'danger',
          // });
        });
    },

    showEditServiceForm(service) {
      this.editServiceModal = true;
      this.serviceToEdit = service;
    },

    resetServiceImagePrompt() {
      this.showServiceImagePrompt = false;
      this.selectedService = null;
    },
    showServiceImageDetail(service) {
      this.selectedService = JSON.parse(JSON.stringify(service));
      this.showServiceImagePrompt = true;
    },
    viewImage(value) {
      window.open(value);
    },

    showStateHistorical(service) {
      this.showHistoricalPrompt = true;
      this.selectedService = service;
    },
    showStateDetail(service) {
      this.showDetailPrompt = true;
      this.detailService = service;
      this.currentStateId = service.state.id;
    },
    confirmCancel(item) {
      this.showCancelDialog = true;
      this.service = item;
    },
    promptChangeState(service) {
      this.activePromptChangeState = true;
      this.selectedService = service;
      this.currentStateId = service.state.id;
    },
  },
  async created() {},
  watch: {
    paymentMethod() {
      this.currentPage = 1;
    },
    currentHistoryStates(data) {
      this.selectedService = data;
    },

    '$apollo.loading'(value) {
      this.loading = value;
    },
  },
  mounted() {
    this.isMounted = true;
  },
});
</script>

<style lang="scss">
.fill-row {
  flex: 0 0 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.con-vs-loading {
  position: relative;
  background: inherit;
}

#div-with-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .service-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table-text {
      text-transform: none !important;
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
